import axios from "axios";

////////////////// Global axios defaults ///////////////////
// const crosAnyWhere = "https://cors-anywhere.herokuapp.com/";
const baseURL = "https://api2.wincoin.asia/";
// const baseURL = window.location.protocol + "//" + window.location.hostname;
axios.defaults.baseURL = baseURL;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.Accept = 'application/json';


////////////////// Private axios defaults ///////////////////
const privateAxiosInstance = axios.create({baseURL: baseURL});
privateAxiosInstance.defaults.headers.common['Authorization'] = localStorage.getItem('token');
privateAxiosInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

////////////////////////////////////////////////////////////
//////////////////////// API List //////////////////////////
////////////////////////////////////////////////////////////


const checkUnAuthorized = (response) => {
    if (response.status === 401) {
        localStorage.removeItem("token");
        window.location.reload()
    }
};

///////////////////////////////////////////
///// BarcodeScanner
///////////////////////////////////////////

//send username and password to server and get access_token
export const loginAPI = (userName, password, callBack) => {
    const qs = require('querystring');
    const requestBody = {
        "username": userName,
        "password": password,
        "grant_type": "password"
    };
    axios.post("token", qs.stringify(requestBody))
    .then(function (response) {
        localStorage.setItem('token', response.data.token_type + " " + response.data.access_token);
        privateAxiosInstance.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        callBack(response)
    }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

///////////////////////////////////////////
///// Register - ActivationCard - Forget Password
///////////////////////////////////////////

// send mobile number to server and server send activation code to user via sms
export const getActivationCodeAPI = (mobile, type, callBack) => {
    axios.get("api/CustomerApp/GetActivationCode?type=" + type + "&mobile=" + mobile)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

// send mobile number activation code too server for check correction of code
export const checkActivationCodeAPI = (mobile, code, callBack) => {
    axios.get("api/CustomerApp/CheckActivationCode?mobile=" + mobile + "&code=" + code)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};


// send data to server for register user
export const registerUserAPI = (userName, VerificationCode, fristName, lastName, email, birthDateShamsi, marriageDateShamsi, gender, ProvinceId, cityId, district, educationId, password, callBack) => {
    const qs = require('querystring');
    const requestBody = {
        "userName": userName,
        "cellphone": userName,
        "fristName": fristName,
        "lastName": lastName,
        "email": email,
        "birthDateShamsi": birthDateShamsi,
        "marriageDateShamsi": marriageDateShamsi,
        "gender": gender,
        "ProvinceId": ProvinceId,
        "cityId": cityId,
        "district": district,
        "educationId": educationId,
        "password": password,
        "VerificationCode": VerificationCode,
        "merchantId": 1
    };
    axios.post("api/CustomerApp/Register", qs.stringify(requestBody)).then(function (response) {
        callBack(response)
    }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

// send new password to server
export const ForgetPassAPI = (mobile, code, password, callBack) => {
    axios.get("api/CustomerApp/ResetPassword?userName=" + mobile + "&code=" + code + "&password=" + password)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

///////////////////////////////////////////
///// Home
///////////////////////////////////////////

// get total user data from server
export const getHomePageDataAPI = (callBack) => {
    privateAxiosInstance.get("api/CustomerApp/Index")
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            checkUnAuthorized(error.response);
            callBack(error.response)
        }
    })
};

///////////////////////////////////////////
///// Shopping centers
///////////////////////////////////////////

// get all shopping centers list from server
export const getAllShoppingCenterAPI = (limit, offset, selectedFilter, selectedSort, selectedState, selectedCity,  callBack) => {
    privateAxiosInstance.get("api/CustomerApp/GetAllShoppingCenter?mid=null&limit=" + limit + "&offset=" + offset + "&selectedCity=" +
        selectedCity + "&selectedState=" + selectedState + "&selectedSort=" + selectedSort + "&selectedFilter=" + selectedFilter)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            checkUnAuthorized(error.response);
            callBack(error.response)
        }
    })
};

// get shopping center by id from server
export const getShoppingCenterAPI = (id, callBack) => {
    privateAxiosInstance.get("api/CustomerApp/GetShoppingCenter?id=" + id )
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            checkUnAuthorized(error.response);
            callBack(error.response)
        }
    })
};

///////////////////////////////////////////
///// Offers
///////////////////////////////////////////

// get all offers list from server
export const getAllOffersAPI = (limit, offset, selectedFilter, selectedSort, selectedState, selectedCity,  callBack) => {
    privateAxiosInstance.get("api/CustomerApp/GetAllOffer?mid=null&limit=" + limit + "&offset=" + offset + "&selectedCity=" +
        selectedCity + "&selectedState=" + selectedState + "&selectedSort=" + selectedSort + "&selectedFilter=" + selectedFilter)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            checkUnAuthorized(error.response);
            callBack(error.response)
        }
    })
};

// get offer by id from server
export const getOfferAPI = (id, callBack) => {
    privateAxiosInstance.get("api/CustomerApp/GetOffer?id=" + id )
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            checkUnAuthorized(error.response);
            callBack(error.response)
        }
    })
};

///////////////////////////////////////////
///// My Info
///////////////////////////////////////////

// get user info from server
export const getMyInfoAPI = (id, callBack) => {
    privateAxiosInstance.get("api/CustomerApp/GetMyInfo?mId=" + id )
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            checkUnAuthorized(error.response);
            callBack(error.response)
        }
    })
};

//get merchants lsit
export const getMerchantsAPI = (callBack) => {
    privateAxiosInstance.get("api/CustomerApp/GetMerchants")
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            checkUnAuthorized(error.response);
            callBack(error.response)
        }
    })
};

///////////////////////////////////////////
///// Points and credit
///////////////////////////////////////////

// get customer credit data from server
export const getCustomerCreditAPI = (limit, offset, callBack) => {
    privateAxiosInstance.get("api/CustomerApp/GetCustomerCredit?limit=" + limit + "&offset=" + offset)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            checkUnAuthorized(error.response);
            callBack(error.response)
        }
    })
};

///////////////////////////////////////////
///// Trasnactins
///////////////////////////////////////////

// get transactions list from server
export const getTransactiontAPI = (merchantId, limit, offset, callBack) => {
    privateAxiosInstance.get("api/CustomerApp/GetTransaction?merchantId=" + merchantId + "&limit=" + limit + "&offset=" + offset)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            checkUnAuthorized(error.response);
            callBack(error.response)
        }
    })
};


///////////////////////////////////////////
///// Referral
///////////////////////////////////////////

// get list of questions and answers from server
export const getReferralDataAPI = (MerchantId, callBack) => {
    privateAxiosInstance.get("api/CustomerApp/GetReferralData?mId=" + MerchantId)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            checkUnAuthorized(error.response);
            callBack(error.response)
        }
    })
};

// referr new customer
export const referrCustomerAPI = (MerchantId, firstName, lastName, cellPhone, productsId, callBack) => {
    const qs = require('querystring');
    const requestBody = {
        "merchantId": MerchantId,
        "firstName": firstName,
        "lastName": lastName,
        "cellPhone": cellPhone,
        "productsId": productsId
    };
    privateAxiosInstance.post("api/CustomerApp/ReferrCustomer", qs.stringify(requestBody)).then(function (response) {
        callBack(response)
    }).catch(function (error) {
        if (error.response) {
            checkUnAuthorized(error.response);
            callBack(error.response)
        }
    })
};

///////////////////////////////////////////
///// Edit Profile
///////////////////////////////////////////

// edit profile data
export const editProfileAPI = (fristName, lastName, email, birthDateShamsi, marriageDateShamsi, gender, ProvinceId,
                               cityId, district, educationId, callBack) => {
    const qs = require('querystring');
    const requestBody = {
        "FristName": fristName,
        "LastName": lastName,
        "Email": email,
        "BirthDateShamsi": birthDateShamsi,
        "MarriageDateShamsi": marriageDateShamsi,
        "Gender": gender,
        "ProvinceId": ProvinceId,
        "CityId": cityId,
        "District": district,
        "EducationId": educationId
    };
    privateAxiosInstance.post("api/CustomerApp/EditProfile", qs.stringify(requestBody)).then(function (response) {
        callBack(response)
    }).catch(function (error) {
        if (error.response) {
            checkUnAuthorized(error.response);
            callBack(error.response)
        }
    })
};


///////////////////////////////////////////
///// FAQ - Terms Of Use
///////////////////////////////////////////

// get list of questions and answers from server
export const getFaqDataAPI = (callBack) => {
    privateAxiosInstance.get("api/customerapp/faq")
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

///////////////////////////////////////////
///// contactUS
///////////////////////////////////////////

// get list of questions and answers from server
export const contactUsAPI = (firstName, lastName, email, mobile, message,callBack) => {
    const qs = require('querystring');
    const requestBody = {
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "mobile": mobile,
        "message": message
    };
    axios.post("api/customerapp/GetComment",qs.stringify(requestBody))
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//login
export const loginUserAPI = (userName, password, callBack) => {
    axios.get(`api/account/Login?userName=${userName}&password=${password}`)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//forget password
export const forgetPasswordAPI = (userName,callBack) => {
    axios.get(`api/account/Forgetpassword?userName=${userName}`)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//get card info
export const getCardInfoAPI = (merchantId,serialNO,callBack) => {
    privateAxiosInstance.get(`api/Card/GetCardInfo?merchantId=${merchantId}&serialNO=${serialNO}`)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//register customer
export const registerCustomerAPI = (form, callBack) => {
    privateAxiosInstance.post("api/Account/RegisterCustomer",{
        ...form
    })
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//get customer info by serial number
export const getCustomerInfoBySerialNoAPI = (CustomerCardNo,merchantId,callBack) => {
    privateAxiosInstance.get(`api/Customer/GetCustomerInfoBySerialNO?CustomerCardNo=${CustomerCardNo}&merchantId=${merchantId}`)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//get customer info by phone number
export const getCustomerInfoByPhoneNoAPI = (mobile,merchantId,callBack) => {
    privateAxiosInstance.get(`api/Customer/GetCustomerInfoByMobile?mobile=${mobile}&merchantId=${merchantId}`)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//preview save
export const previewSaveAPI = (form,callBack) => {
    privateAxiosInstance.post("api/Transaction/PreviewSave",form)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//save transaction
export const saveTransactionAPI = (UId,callBack) => {
    privateAxiosInstance.get(`api/Transaction/Save?UId=${UId}`)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//send transaction message
export const sendTransactionMessageAPI = (factorNo,callBack) => {
    privateAxiosInstance.get(`api/Transaction/SendMessageTransaction?factorNo=${factorNo}`)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};


//get all customer groups
export const getAllCustomerGroupsAPI = (parentId,merchantid,callBack) => {
    privateAxiosInstance.get(`api/CustomerGroup/GetAllCustomerGroups?parentId=${parentId}&merchantid=${merchantid}`)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//get all product groups
export const getAllProductAPI = (merchantId,parentId,callBack) => {
    privateAxiosInstance.get(`api/ProductGroup/GetAllProduct?merchantId=${merchantId}&parentId=${parentId}`)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//credit deduction
export const creditDeductionAPI = (form,callBack) => {
    privateAxiosInstance.post("api/Credit/CreditDeduction",{
        ...form
    })
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//credit increase
export const chargGiftCardAPI = (amount,serialNo,merchantTypeId,MerchantId,callBack) => {
    privateAxiosInstance.get(`api/Card/ChargGiftCard?amount=${amount}&serialNo=${serialNo}&merchantTypeId=${merchantTypeId}&MerchantId=${MerchantId}`)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//chang number card
export const changCardNoAPI = (newSerialNo,oldSerialNo,callBack) => {
    privateAxiosInstance.get(`api/Card/ChangCardNo?newSerialNo=${newSerialNo}&oldSerialNo=${oldSerialNo}&reasonchange=`)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//get merchant by parentId
export const getMerchantByParentIdAPI = (parentId,callBack) => {
    privateAxiosInstance.get(`api/Merchant/GetMerchantByParentId?parentId=${parentId}`)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//check status card
export const checkStatusCardAPI = (serialNo,merchantId,callBack) => {
    privateAxiosInstance.get(`api/Card/CheckStatusCard?serialNo=${serialNo}&merchantId=${merchantId}`)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
};

//get virtual card for transaction registration
export const getVirtualCard = (merchantId,callBack) => {
    privateAxiosInstance.get(`api/CustomerApp/GetVirtualCard?merchantId=${merchantId}`)
        .then(function (response) {
            callBack(response)
        }).catch(function (error) {
        if (error.response) {
            callBack(error.response)
        }
    })
    
};