import React, {useState} from 'react';
import './BarcodeScanner.css';
import Menu from "../../components/Menu/Menu";
import ActionBar from "../../components/ActionBar/ActionBar";
import menu from "../../assets/images/Menu/menu.svg";
import {Redirect} from "react-router-dom";
import back from "../../assets/images/back.svg";
import Input from "../../components/Input/Input";
import { BrowserBarcodeReader } from '@zxing/library';
import { ToastsStore } from 'react-toasts';
import {
    getVirtualCard
  } from "../../api/api";
const codeReader = new BrowserBarcodeReader();

function BarcodeScanner(props) {

    const [showMenu, setShowMenu] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [barcode, setBarcode] = useState("");


    // const getTitle = () => {
    //     switch (props.match.params.type) {
    //         case "1":
    //             return "عضویت مشتریان";
    //         case "2":
    //             return "ثبت تراکنش";
    //         case "3":
    //             return "کسر از اعتبار";
    //         case "4":
    //             return "افزایش اعتبر";
    //         case "5":
    //             return "تغییر کارت";
    //     }
    // };


    // const getColor = () => {
    //     switch (props.match.params.type) {
    //         case "1":
    //             return "#e91d62";
    //         case "2":
    //             return "#ce0c00";
    //         case "3":
    //             return "#007bec";
    //         case "4":
    //             return "#3e50b4";
    //         case "5":
    //             return "#5da600";
    //     }
    // };


    //use virtual card
    const requestVirtualCard = () => {
        getVirtualCard(JSON.parse(localStorage.getItem('userAccountInfo')).Id, response => {
            if (response.status === 200) {
                console.log(response)
                if(response.data?.data){
                    response.data.errorMessage ? ToastsStore.error(response.data.errorMessage) : setBarcode(response.data?.data);
                }
                else{
                    ToastsStore.error('سریال های مجازی شما به اتمام رسیده است.')
                }
            } else if (response.status === 400) {
                ToastsStore.error(response.data.ErrorMessage);
            }
        });
    };

    const handleStart = async () => {
        if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
            setShowVideo(true);
            const mediaStream = await navigator.mediaDevices.getUserMedia({video: {facingMode: 'environment'}});

            codeReader
                .listVideoInputDevices()
                .then(videoInputDevices => {
                    codeReader
                        .decodeOnceFromVideoDevice(mediaStream.deviceId, 'video')
                        .then(result => {
                            setBarcode(result.text);
                            codeReader.reset();
                            setShowVideo(false)
                        })
                        .catch(err => console.error(err));
                })
                .catch(err => console.error(err));
        } else {
            alert("دستکاه شما استفاده از بارکد خوان را پشتیبانی نمیکند")
        }
    };


        return (
            <div className="barcode-scanner-container unselectable">
                <Menu show={showMenu} hide={() => setShowMenu(false)}/>
                <ActionBar title={props.title} color={props.color}
                           leftIcon={back} leftClick={() => props.actionBack.goBack()}
                           rightIcon={menu} rightClick={() => setShowMenu(!showMenu)}/>
                <div className="barcode-scanner-container__content">
                    {showVideo &&
                        <video
                            id="video"
                            width="400"
                            height="300"
                            style={{ "border": "1px solid gray" }}
                        />
                    }
                    <div className="barcode-scanner-container__content-__barcode">
                        <Input type="mobile" placeholder="سریال کارت" value={barcode}
                               setValue={(value) => setBarcode(value)}
                               style={{textAlign: "right", borderColor: props.color, borderBottomWidth: "2px", lineHeight: "40px",
                               paddingLeft: "40px"}}/>
                        <img src={require("../../assets/images/camera.png")} alt="" onClick={()=>{
                            if(showVideo){
                                codeReader.reset();
                                setShowVideo(false)
                            } else {
                                handleStart();
                            }
                        }}/>
                    </div>
                    {props.title === "ثبت تراکنش"
                        ?
                        <button style={{backgroundColor: props.color}} onClick={() => props.receiveBarcode(barcode) } >
                            ارسال
                        </button>
                        :
                        props.title==="افزایش اعتبار"
                        ?
                        <button style={{backgroundColor: props.color}} onClick={() => props.receiveBarcode(barcode) } >
                        ارسال
                    </button>
                        :
                        <>
                         {props.virtualCard && <div className="barcode-scanner-container__content__barcode__btn-virtual-card" onClick={requestVirtualCard}>استفاده از کارت مجازی</div>}
                        <button style={{backgroundColor: props.color}} onClick={() => barcode.length === 16 ? props.receiveBarcode(barcode) : ToastsStore.error("شماره کارت باید 16 رقم باشد")} >
                            ارسال
                        </button>
                        </>
                    }
                </div>
            </div>
        );
}

export default BarcodeScanner;
