import React, {useState, useEffect} from 'react';
import './AddToHomeScreen.css';
import logo from "../../assets/images/logo.svg";
import addIcon from "../../assets/images/AddToHomeScreen/add.svg";
import moreIcon from "../../assets/images/AddToHomeScreen/more.svg";
import shareIcon from "../../assets/images/AddToHomeScreen/share.svg";


export default function Loading(props) {

    const [show, setShow] = useState(false);

    const getMobileOperatingSystem = () => {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            return "Android";
        } else  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        } else {
            return false;
        }
    };

    useEffect(() => {
       if (localStorage.getItem("showAdd") === "1" || window.matchMedia('(display-mode: standalone)').matches) {
           setShow(false)
       } else {
           setShow(true)
       }
    }, [localStorage.getItem("showAdd")]);

    if(show && getMobileOperatingSystem()){
        return (
            <div className="add-to-home-screen  unselectable">
                {getMobileOperatingSystem() === "Android" &&
                <div className="android">
                    <img src={logo}/>
                    <h2>افزودن اپلیکیشن پیشرو وین کوین</h2>
                    <h3>با این کار همواره می توانید به آسانی از خدمات وین کوین استفاده کنید</h3>
                    <div>
                        <p>۱. دکمه More را در نوار پایین کلیک کنید<img src={moreIcon}/></p>
                        <p>۲. گزینه Add to Home Screen را انتخاب کنید</p>
                        <p>۳. در قسمت باز شده بر روی Add کلیک کنید<span>Add</span></p>
                    </div>
                    <button onClick={()=> {
                        setShow(false);
                        localStorage.setItem("showAdd", "1")
                    }}>متوجه شدم</button>
                </div>
                }
                {getMobileOperatingSystem() === "iOS" &&
                <div className="ios">
                    <img src={logo}/>
                    <h2>افزودن اپلیکیشن پیشرو وین کوین</h2>
                    <h3>با این کار همواره می توانید به آسانی از خدمات وین کوین استفاده کنید</h3>
                    <div>
                        <p>۱. دکمه Share را در نوار پایین کلیک کنید<img src={shareIcon}/></p>
                        <p>۲. گزینه Add to Home Screen را انتخاب کنید<img src={addIcon}/></p>
                        <p>۳. در قسمت بالا بر روی Add کلیک کنید<span>Add</span></p>
                    </div>
                    <button onClick={()=> {
                        setShow(false);
                        localStorage.setItem("showAdd", "1")
                    }}>متوجه شدم</button>
                </div>
                }
            </div>
        )
    } else {
        return null;
    }



}