import React from 'react';
import './Checkbox.css';

function Checkbox(props) {

    //action empty & invalid input
    const invalid = (event) => {
        event.target.className = props.setting.invalidClass;
    };

    //check value input
    const validation = (event) => {
        //props.setValue(event.target.value)
        props.target(event.target)
        event.target.className = ""
    };

    //generate input
    const generateInput = () => {
        if (props.setting.required) {
            //required field
            return (
                <div className={props.setting.checkboxClass} >
                    {props.labelAndValue.map((item, index) =>
                        <label key={index}>{item.label}
                            <input value={item.value} type={props.setting.type} name={props.name} onChange={validation} onInvalid={invalid} required />
                        </label>
                    )}
                </div>
            );
        } else {
            //normal field
            return (
                <div className={props.setting.checkboxClass} >
                    {props.labelAndValue.map((item, index) =>
                        <label key={index}>{item.label}
                            <input value={item.value} type={props.setting.type} name={props.name} onChange={validation} />
                        </label>
                    )}
                </div>
            );

        }
    };

    return (
        <div className={props.setting.class}>
            {generateInput()}
        </div>
    )

}

export default Checkbox;