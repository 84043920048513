import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import BarcodeScanner from "../../components/BarcodeScanner/BarcodeScanner";
import Input from "../../components/InputTwo/Input/Input";
import DatePicker from "../../components/InputTwo/DatePicker/DatePicker";
import { ToastsStore } from "react-toasts";
import "./Membership.css";
import Loading from "../../components/Loading/Loading";
import {
  registerCustomerAPI,
  getCardInfoAPI,
  getAllCustomerGroupsAPI,
  getMerchantByParentIdAPI,
} from "../../api/api";

function Membership(props) {
  const [loading, setLoading] = useState({ show: false, message: "" });

  //get the barcode number written by the user in components/BarcodeScanner
  const [userBarcode, setUserBarcode] = useState();

  const [showForm, setshowForm] = useState(false);

  //get information card from API
  const [card, setCard] = useState({});

  //get branch list from API
  const [listBranch, setListBranch] = useState({
    name: [],
    id: [],
  });

  //get list customer groups from API
  const [customerGroups, setCustomerGroups] = useState({
    name: [],
    id: [],
  });

  const [form, setForm] = useState({
    EditMode: false,
    BirthDateShamsi: "",
    CardSerialNumber: "",
    CellPhone: "",
    CreateBy: "",
    FristName: "",
    Gender: "",
    LastName: "",
    Description: "",
    MerchantId: "",
    Phone: "",
    UserName: "",
    Password: 123456,
    ConfirmPassword: 123456,
    Email: "",
    ExclusiveCol1: "",
    ExclusiveCol2: "",
    ExclusiveCol3: "",
    CustomerGroupId: "",
    MarriageDateShamsi: "",
  });

  useEffect(() => {
    setLoading({ ...loading, show: true });
    setForm({
      EditMode: false,
      BirthDateShamsi: "",
      CardSerialNumber: "",
      CellPhone: "",
      CreateBy: JSON.parse(localStorage.getItem("userAccountInfo")).CreatedBy,
      FristName: "",
      Gender: "",
      LastName: "",
      Description: "",
      MerchantId: JSON.parse(localStorage.getItem("userAccountInfo")).Id,
      Phone: "",
      UserName: "",
      Password: 123456,
      ConfirmPassword: 123456,
      Email: "",
      ExclusiveCol1: "",
      ExclusiveCol2: "",
      ExclusiveCol3: "",
      CustomerGroupId: "",
      MarriageDateShamsi: "",
    });

    //get list customer groups from API
    let flagustomerGroupsName = [];
    let flagustomerGroupsId = [];
    getAllCustomerGroupsAPI(
      JSON.parse(localStorage.getItem("userAccountInfo")).ParentId,
      JSON.parse(localStorage.getItem("userAccountInfo")).Id,
      (response) => {
        if (response.status === 200) {
          response.data.data.map((item) => {
            flagustomerGroupsName.push(item.title);
            flagustomerGroupsId.push(item.id);
          });
        } else if (response.status === 400) {
          ToastsStore.error(response.data.errorMessage);
        }
      }
    );
    setCustomerGroups({ name: flagustomerGroupsName, id: flagustomerGroupsId });

    //get branch list API
    if (!JSON.parse(localStorage.getItem("userAccountInfo")).ParentId) {
      let flagBranchName = [];
      let flagBranchId = [];
      getMerchantByParentIdAPI(
        JSON.parse(localStorage.getItem("userAccountInfo")).Id,
        (response) => {
          setLoading({ ...loading, show: false });
          if (response.status === 200) {
            response.data.map((item) => {
              flagBranchName.push(item.brandName);
              flagBranchId.push(item.id);
            });
          } else if (response.status === 400) {
            ToastsStore.error(response.data.errorMessage);
          }
        }
      );
      setListBranch({ name: flagBranchName, id: flagBranchId });
    }
  }, [userBarcode]);

  useEffect(() => {
    if (userBarcode) {
      setLoading({ ...loading, show: true });
      setCard({});

      getCardInfoAPI(
        JSON.parse(localStorage.getItem("userAccountInfo")).Id,
        userBarcode,
        (response) => {
          setLoading({ ...loading, show: false });
          console.log(response);
          if (response?.status === 200) {
            if (response.data.Data) {
              setCard(response.data.Data);
              setForm({
                ...form,
                EditMode: false,
                CardSerialNumber: userBarcode,
              });
              setshowForm(true);
            } else {
              setshowForm(false);

              ToastsStore.error(response.data.ErrorMessage);
            }
          } else if (response?.status === 400) {
            setshowForm(false);

            ToastsStore.error(response.data.errorMessage);
          }
        }
      );
    }
  }, [userBarcode]);

  const sendForm = (event) => {
    event.preventDefault();
    if (form.Phone.length === 11) {
      setLoading({ ...loading, show: true });
      registerCustomerAPI(form, (response) => {
        setLoading({ ...loading, show: false });
        setUserBarcode();
        if (response.status === 200) {
          response.data.errorMessage
            ? ToastsStore.error(response.data.errorMessage)
            : ToastsStore.success("کاربر جدید با موفقیت ثبت شد");
        } else if (response.status === 400) {
          ToastsStore.error(response.data.errorMessage);
        }
      });
    } else if (form.Phone.length === 0) {
      ToastsStore.error("وارد کردن شماره همراه الزامی است");
    } else {
      ToastsStore.error("شماره موبایل باید 11 رقم باشد");
    }
  };

  //convert name select branch to id
  const convertBranchToId = (event) => {
    //search ID
    listBranch.name.map((item, index) => {
      if (event === item) {
        setForm({ ...form, MerchantId: listBranch.id[index] });
      }
    });
  };

  //convert name select customerGroups to id
  const convertCustomerGroupsToId = (event) => {
    //multi select
    let flagUserSelect = [];
    for (let i in event) {
      if (event[i].selected) {
        //search ID
        customerGroups.name.map((item, index) => {
          if (event[i].label === item) {
            flagUserSelect.push(customerGroups.id[index]);
          }
        });
      }
    }
    setForm({ ...form, CustomerGroupId: flagUserSelect.toString() });
  };

  //setting input
  const settingDropDownBranch = {
    class: "input-field__membership--drop-down",
    type: "drop-down list",
    optionValue: listBranch.name,
    selectDefault: "شعبه",
  };
  const settingDropDownCustomerGroup = {
    class: "input-field__membership--drop-down",
    type: "drop-down list",
    optionValue: customerGroups.name,
    selectDefault: "گروه مشتریان",
    multiple: true,
  };
  const settingMobile = {
    class: "input-field__membership--mobile",
    type: "tel",
    pattern: "phone",
  };
  const settingText = {
    class: "input-field__membership--text",
    type: "text",
  };
  const settingTextarea = {
    class: "input-field__membership--textarea",
    type: "textarea",
  };
  const settingDate = {
    class: "input-field--date",
    groupClass: "input-field__group-date__membership",
  };
  const settingRadioMarital = {
    class: "input-field--radio",
    type: "radio",
    radioClass: "radio-01__membership",
    labelAndValue: [
      { label: "مجرد", value: "مجرد" },
      { label: "متاهل", value: "متاهل" },
    ],
  };
  const settingRadioGender = {
    class: "input-field--radio",
    type: "radio",
    radioClass: "radio-01__membership",
    labelAndValue: [
      { label: "مرد", value: "مرد" },
      { label: "زن", value: "زن" },
    ],
  };

  if (localStorage.getItem("token") != null)
    return (
      <>
        <BarcodeScanner
          title="عضویت مشتریان"
          color="var(--membership)"
          actionBack={props.history}
          receiveBarcode={setUserBarcode}
          virtualCard={true} 
        />
        {showForm ? (
          <div className="membership-container unselectable">
            <Loading loading={loading.show} message={loading.message} />
            <div className="membership-container__card">
              <p className="membership-container__card__text">
                شماره سریال : {card.SerialNo ? card.SerialNo : ""}
              </p>
              <p className="membership-container__card__text">
                میزان هدیه کارت :{" "}
                {card.giftCreditAmount ? card.giftCreditAmount : ""}
              </p>
              <p className="membership-container__card__text">
                گروه کارت : {card.CardGroupName ? card.CardGroupName : ""}
              </p>
            </div>
            <form
              className="membership-container__form"
              onSubmit={(event) => sendForm(event)}
            >
              <div className="membership-container__form__group-input">
                {listBranch.name.length > 0 ? (
                  <Input
                    setting={settingDropDownBranch}
                    setValue={(event) => convertBranchToId(event)}
                  />
                ) : null}
                <Input
                  setting={settingMobile}
                  placeholder="شماره همراه"
                  setValue={(event) =>
                    setForm({
                      ...form,
                      UserName: event,
                      CellPhone: event,
                      Phone: event,
                    })
                  }
                />
                <Input
                  setting={settingText}
                  placeholder="نام"
                  setValue={(event) => setForm({ ...form, FristName: event })}
                />
                <Input
                  setting={settingText}
                  placeholder="نام خانوادگی"
                  setValue={(event) => setForm({ ...form, LastName: event })}
                />
                <Input
                  setting={settingRadioGender}
                  name="gender"
                  label="جنسیت"
                  setValue={(event) => setForm({ ...form, Gender: event })}
                />
                <DatePicker
                  setting={settingDate}
                  label="تاریخ تولد"
                  setValue={(event) =>
                    setForm({ ...form, BirthDateShamsi: event })
                  }
                />
                <DatePicker
                  setting={settingDate}
                  label="تاریخ ازدواج"
                  setValue={(event) =>
                    setForm({ ...form, MarriageDateShamsi: event })
                  }
                />
                <Input
                  setting={settingRadioMarital}
                  name="marital"
                  label="وضعیت تاهل"
                  setValue={(event) =>
                    setForm({ ...form, maritalStatus: event })
                  }
                />
                <Input
                  setting={settingDropDownCustomerGroup}
                  setValue={(event) => convertCustomerGroupsToId(event)}
                />
                <Input
                  setting={settingText}
                  placeholder="فیلد 1"
                  setValue={(event) =>
                    setForm({ ...form, ExclusiveCol1: event })
                  }
                />
                <Input
                  setting={settingText}
                  placeholder="فیلد 2"
                  setValue={(event) =>
                    setForm({ ...form, ExclusiveCol2: event })
                  }
                />
                <Input
                  setting={settingText}
                  placeholder="فیلد 3"
                  setValue={(event) =>
                    setForm({ ...form, ExclusiveCol3: event })
                  }
                />
                <Input
                  setting={settingTextarea}
                  placeholder="توضیحات"
                  setValue={(event) => setForm({ ...form, Description: event })}
                />
              </div>
              <div className="membership-container__form__button">
                <div className="membership-container__form__button--success">
                  <input type="submit" value="ثبت و افزودن مشتری" />
                </div>
                <div
                  onClick={() => setUserBarcode()}
                  className="membership-container__form__button--cancel"
                >
                  <span>انصراف</span>
                </div>
              </div>
            </form>
          </div>
        ) : null}
      </>
    );
  else return <Redirect to="/login" />;
}

export default Membership;
