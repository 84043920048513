import React, {useState} from 'react';
import './Login.css';
import Menu from "../../components/Menu/Menu";
import ActionBar from "../../components/ActionBar/ActionBar";
import logo from "../../assets/images/logo.png";
import menu from "../../assets/images/Menu/menu.svg";
import Input from "../../components/Input/Input";
import {Link, Redirect} from "react-router-dom";
import {ToastsStore} from 'react-toasts';
import {loginAPI,loginUserAPI} from "../../api/api";
import Loading from "../../components/Loading/Loading";


function Login(props) {

    const [loading, setLoading] = useState({show: false, message: "در حال ورود"});
    const [showMenu, setShowMenu] = useState(false);
    const [loginData, setLoginData] = useState({
        mobile: "",
        password: ""
    });

    const login = () => {
        const phoneNum = /^(9|09)(12|19|30|33|35|36|37|38|39|32|21|03|02|04|05|41|31|34|01|10|11|13|14|15|16|17|18|90|91|92)\d{7}$/;
        if (!loginData.mobile.match(phoneNum)) {
            ToastsStore.error("شماره موبایل معتبر نیست");
        } else if (loginData.password.length === 0) {
            ToastsStore.error("رمز عبور معتبر نیست");
        } else {
            setLoading({...loading, show: true});
            loginAPI(loginData.mobile, loginData.password, response => {
                setLoading({...loading, show: false});
                if (response.data.status === 200) {
                    window.location.reload()
                } else if (response.status === 400) {
                    ToastsStore.error(response.data.error);
                }
            })


            loginUserAPI(loginData.mobile, loginData.password, response => {
                setLoading({...loading, show: false});
                if (response.status === 200) {
                    if(response.data.data) {
                        localStorage.setItem('userAccountInfo',JSON.stringify(response.data.data))
                        window.location.reload()
                    } else {
                        ToastsStore.error(response.data.errorMessage);
                    }
                } else if (response.status === 400) {
                    ToastsStore.error(response.data.errorMessage);
                }
            });


        }
    };

    if (localStorage.getItem('token') != null && localStorage.getItem('userAccountInfo')) {
        return (
            <Redirect to='/'/>
        );
    } else {
        return (
            <div className="login-container unselectable">
                <Loading loading={loading.show} message={loading.message}/>
                <Menu show={showMenu} hide={() => setShowMenu(false)}/>
                <ActionBar logo={logo} rightIcon={menu} rightClick={() => setShowMenu(!showMenu)}/>
                <div className="inputs">
                    <Input label="شماره همراه" type="mobile" placeholder="mobile" value={loginData.mobile}
                           setValue={(value) => setLoginData({...loginData, mobile: value})}/>
                    <Input label="رمز عبور" type="password" placeholder="password" value={loginData.password}
                           setValue={(value) => setLoginData({...loginData, password: value})}/>
                    <button onClick={login}>ورود به برنامه</button>
                    <Link to="/forget-password">فراموشی کلمه عبور</Link>
                </div>
            </div>
        );
    }
}

export default Login;
