import React from 'react';
import './Loading.css';


/**
 * @return {null}
 */
export default function Loading(props) {

    if (props.loading){
        return (
            <div className="loading-dialog">
                <div className="lds-roller">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
                <p>{props.message}</p>
            </div>
        )
    } else {
        return null;
    }

}