import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import BarcodeScanner from '../../components/BarcodeScanner/BarcodeScanner';
import Input from '../../components/InputTwo/Input/Input';
import './DecreaseCredit.css';
import Loading from "../../components/Loading/Loading";
import { ToastsStore } from 'react-toasts';
import { getCustomerInfoBySerialNoAPI, creditDeductionAPI } from "../../api/api";

function DecreaseCredit(props) {

    const [loading, setLoading] = useState({ show: false, message: "" });

    //get the barcode number written by the user in components/BarcodeScanner
    const [userBarcode, setUserBarcode] = useState();

    //get information from API
    const [card, setCard] = useState({
        cardSerialNumber: '',
        remainCredit: '',
        cellPhone: '',
        fristName: '',
        lastName: '',
        point: ''
    });

    const [form, setForm] = useState({
        MerchantId: '',
        CustomerId: '',
        CustomeCardNo: '',
        Amount: '',
        OperatorId: ''
    });

    useEffect(() => {
        setForm({
            MerchantId: JSON.parse(localStorage.getItem('userAccountInfo')).Id,
            OperatorId: JSON.parse(localStorage.getItem('userAccountInfo')).OPeratorId,
            CustomeCardNo: '',
            CustomerId: '',
            Amount: ''
        });
        if (userBarcode) {
            setLoading({ ...loading, show: true });
            setCard({});

            //get info customer from API
            getCustomerInfoBySerialNoAPI(userBarcode, JSON.parse(localStorage.getItem('userAccountInfo')).Id, response => {
                setLoading({ ...loading, show: false });
                if (response.status === 200) {
                    if (response.data.data) {
                        setCard(response.data.data)
                        setForm({
                            MerchantId: JSON.parse(localStorage.getItem('userAccountInfo')).Id,
                            OperatorId: JSON.parse(localStorage.getItem('userAccountInfo')).OPeratorId,
                            CustomeCardNo: userBarcode,
                            CustomerId: response.data.data.customerId,
                            Amount: ''
                        });
                    } else {
                        ToastsStore.error(response.data.errorMessage)
                    }
                } else if (response.status === 400) {
                    ToastsStore.error(response.data.errorMessage);
                }
            });
        }
    }, [userBarcode])

    const sendForm = (event) => {
        event.preventDefault()
        setLoading({ ...loading, show: true });
        creditDeductionAPI(form, response => {
            setLoading({ ...loading, show: false });
            setUserBarcode()
            if (response.status === 200) {
                response.data.errorMessage ? ToastsStore.error(response.data.errorMessage) : ToastsStore.success("کاهش اعتبار با موفقیت انجام شد");
            } else if (response.status === 400) {
                ToastsStore.error(response.data.errorMessage);
            }
        });
    };

    //setting input
    const settingTextRequired = {
        class: "input-field__decrease-credit--text",
        type: 'text',
        required: true,
        invalidClass: "input-field__decrease-credit--text--alert-required"
    };

    if (localStorage.getItem('token') != null)
        return (
            <>
                <BarcodeScanner title="کاهش اعتبار" color="var(--decrease-credit)" actionBack={props.history} receiveBarcode={setUserBarcode} />
                {userBarcode
                    ?
                    <div className="Decrease-credit-container unselectable">
                        <Loading loading={loading.show} message={loading.message} />
                        <div className="Decrease-credit-container__card">
                            <p className="Decrease-credit-container__card__text" >اعتبار قابل برداشت : {card.remainCredit ? card.remainCredit : ''}</p>
                            <p className="Decrease-credit-container__card__text" >شماره سریال : {card.cardSerialNumber ? card.cardSerialNumber : ''}</p>
                            <p className="Decrease-credit-container__card__text" >شماره همراه مشتری : {card.cellPhone ? card.cellPhone : ''}</p>
                            <p className="Decrease-credit-container__card__text" >نام و نام خانوادگی : {card.fristName ? `${card.fristName} ${card.lastName}` : ''}</p>
                            <p className="Decrease-credit-container__card__text" >امتیاز فعلی : {card.point ? card.point : ''}</p>
                        </div>
                        <form className="Decrease-credit-container__form" onSubmit={(event) => sendForm(event)}>
                            <div className="Decrease-credit-container__form__group-input">
                                <Input setting={settingTextRequired} label="میزان کسر از اعتبار" setValue={(event) => setForm({ ...form, Amount: event })} />
                            </div>
                            <div className="Decrease-credit-container__form__button">
                                <div className="Decrease-credit-container__form__button--success">
                                    <input type="submit" value="کاهش اعتبار" />
                                </div>
                                <div onClick={() => setUserBarcode(false)} className="Decrease-credit-container__form__button--cancel">
                                    <span>انصراف</span>
                                </div>
                            </div>
                        </form>
                    </div>
                    :
                    null
                }
            </>
        );
    else
        return <Redirect to='/login' />
}

export default DecreaseCredit;
