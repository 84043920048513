import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import BarcodeScanner from "../../components/BarcodeScanner/BarcodeScanner";
import Input from "../../components/InputTwo/Input/Input";
import Checkbox from "../../components/InputTwo/Checkbox/Checkbox";
import "./TransactionRegistration.css";
import Menu from "../../components/Menu/Menu";
import ActionBar from "../../components/ActionBar/ActionBar";
import Loading from "../../components/Loading/Loading";
import { ToastsStore } from "react-toasts";
import {
  getCustomerInfoBySerialNoAPI,
  getAllProductAPI,
  previewSaveAPI,
  getMerchantByParentIdAPI,
  saveTransactionAPI,
  getCustomerInfoByPhoneNoAPI, sendTransactionMessageAPI
} from "../../api/api";
//it is related to components actionBar
import menu from "../../assets/images/Menu/menu.svg";
import back from "../../assets/images/back.svg";

function TransactionRegistration(props) {
  const [loading, setLoading] = useState({ show: false, message: "" });

  //get the barcode number written by the user in components/BarcodeScanner
  const [userBarcode, setUserBarcode] = useState();
  const [page, setPage] = useState();

  //get branch list from API
  const [listBranch, setListBranch] = useState({
    name: [],
    id: [],
  });

  //get Product list from API
  const [productList, setProductList] = useState({
    allData: [],
    parent: [],
    childOne: [],
    childTwo: [],
    childThirds: [],
  });

  //get information from API
  const [card, setCard] = useState({
    cardSerialNumber: "",
    remainCredit: "",
    cellPhone: "",
    fristName: "",
    lastName: "",
    point: "",
    dedicatedCredit: ''
  });

  const [cardAnswer, setCardAnswer] = useState({
    customerCredit: "",
    shoppingAmount: "",
    payableAmount: "",
    remaningCredit: "",
    newSumCredit: "",
    offerCredit: "",
    discountOffer: "",
    newCredit: "",
    usingCredit: "",
  });

  //array form
  const [cardInput, setCardInput] = useState([
    {
      MerchantId: JSON.parse(localStorage.getItem("userAccountInfo")).Id,
      CustomerId: "",
      CustomeCardNo: "",
      Amount: "",
      OperatorId: JSON.parse(localStorage.getItem("userAccountInfo"))
        .OPeratorId,
      Description: "",
      ProductGroupId: "",
      IsUsedCredit: false,
      FactorNoMerchant: "",
      invoiceNumber: "",
      textOfTransaction: "",
    },
    {
      MerchantId: JSON.parse(localStorage.getItem("userAccountInfo")).Id,
      CustomerId: "",
      CustomeCardNo: "",
      Amount: "",
      OperatorId: JSON.parse(localStorage.getItem("userAccountInfo"))
        .OPeratorId,
      Description: "",
      ProductGroupId: "",
      IsUsedCredit: false,
      FactorNoMerchant: "",
      invoiceNumber: "",
      textOfTransaction: "",
    },
    {
      MerchantId: JSON.parse(localStorage.getItem("userAccountInfo")).Id,
      CustomerId: "",
      CustomeCardNo: "",
      Amount: "",
      OperatorId: JSON.parse(localStorage.getItem("userAccountInfo"))
        .OPeratorId,
      Description: "",
      ProductGroupId: "",
      IsUsedCredit: false,
      FactorNoMerchant: "",
      invoiceNumber: "",
      textOfTransaction: "",
    },
  ]);

  useEffect(() => {
    
    if (!JSON.parse(localStorage.getItem("userAccountInfo")).ParentId) {
      let flagName = [];
      let flagId = [];
      getMerchantByParentIdAPI(
        JSON.parse(localStorage.getItem("userAccountInfo")).Id,
        (response) => {
          if (response.status === 200) {
            response.data.map((item) => {
              flagName.push(item.brandName);
              flagId.push(item.id);
            });
          } else if (response.status === 400) {
            ToastsStore.error(response.data.errorMessage);
          }
        }
      );
      setListBranch({ name: flagName, id: flagId });
    }

    //get list all product
    getAllProductAPI(
      JSON.parse(localStorage.getItem("userAccountInfo")).Id,
      JSON.parse(localStorage.getItem("userAccountInfo")).ParentId,
      (response) => {
        if (response.status === 200) {
          let flagProductParent = [];
          //list product parent
          response.data.data.map((item) => {
            if (item.productParentId === null) {
              flagProductParent.push(item.productName);
            }
          });

          setProductList({
            ...productList,
            allData: response.data.data,
            parent: flagProductParent,
          });
        } else if (response.status === 400) {
          ToastsStore.error(response.data.ErrorMessage);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (userBarcode) {
      setLoading({ ...loading, show: true });
      setCard({});

      if (userBarcode.length === 16) {
        for (let i = 0; i < 3; i++) {
          setCardInput(
            [...cardInput],
            (cardInput[i].CustomeCardNo = userBarcode)
          );
        }
        //get info customer from API (card)
        getCustomerInfoBySerialNoAPI(
          userBarcode,
          JSON.parse(localStorage.getItem("userAccountInfo")).Id,
          (response) => {
            setLoading({ ...loading, show: false });
            if (response.status === 200) {
              if (response.data.data) {
                setPage("form");

                setCard(response.data.data);
                for (let i = 0; i < 3; i++) {
                  setCardInput(
                    [...cardInput],
                    (cardInput[i].IsUsedCredit = response.data.data.usedCredit),
                    (cardInput[i].CustomerId = response.data.data.customerId)
                  );
                }
              } else {
            setPage();

                ToastsStore.error(response.data.errorMessage);
              }
            } else if (response.status === 400) {
            setPage();

              ToastsStore.error(response.data.errorMessage);
            }
          }
        );
      } else {
        //get info customer from API (phone)
        getCustomerInfoByPhoneNoAPI(
          userBarcode,
          JSON.parse(localStorage.getItem("userAccountInfo")).Id,
          (response) => {
            setLoading({ ...loading, show: false });
            if (response.status === 200) {
              if (response.data.data) {
            setPage("form");

                setCard(response.data.data);
                for (let i = 0; i < 3; i++) {
                  setCardInput(
                    [...cardInput],
                    (cardInput[i].IsUsedCredit = response.data.data.usedCredit),
                    (cardInput[i].CustomerId = response.data.data.customerId),
                    (cardInput[i].CustomeCardNo =
                      response.data.data.cardSerialNumber)
                  );
                }
              } else {
            setPage();

                ToastsStore.error(response.data.errorMessage);
              }
            } else if (response.status === 400) {
            setPage();

              ToastsStore.error(response.data.errorMessage);
            }
          }
        );
      }
    }
    // there was a loading and cardInput dep here
  }, [userBarcode]);

  //make child list of parent select
  const makerChild = (event, numberState, numberSelect) => {
    //search ID & seve to form id select
    let flagId;
    productList.allData.map((item) => {
      if (event === item.productName) {
        flagId = item.productId;
        setCardInput(
          [...cardInput],
          (cardInput[numberSelect].ProductGroupId = item.productId)
        );
      }
    });
    //make list child product
    let arrayList = productList.allData.filter((item) => {
      return item.productParentId === flagId;
    });
    //set state name child product
    let flagProductChild = [];
    arrayList.map((item) => {
      flagProductChild.push(item.productName);
    });
    setProductList({
      ...productList,
      ["child" + numberState]: flagProductChild,
    });
  };

  //convert name select child product to id
  const convertChildToId = (event, numberState) => {
    //search ID
    productList.allData.map((item) => {
      if (event === item.productName) {
        setCardInput(
          [...cardInput],
          (cardInput[numberState].ProductGroupId = item.productId)
        );
      }
    });
  };

  //convert name select branch to id
  const convertBranchToId = (event) => {
    //search ID
    listBranch.name.map((item, index) => {
      if (event === item) {
        for (let i = 0; i < 3; i++) {
          setCardInput(
            [...cardInput],
            (cardInput[i].MerchantId = listBranch.id[index])
          );
        }
      }
    });
  };

  //set invoiceNumber & textOfTransaction to all card
  const invoiceNumberToAllCard = (event) => {
    for (let i = 0; i < 3; i++) {
      setCardInput([...cardInput], (cardInput[i].invoiceNumber = event));
    }
  };
  const textOfTransactionToAllCard = (event) => {
    for (let i = 0; i < 3; i++) {
      setCardInput([...cardInput], (cardInput[i].textOfTransaction = event));
    }
  };

  const sendForm = (event) => {
    event.preventDefault();

    //check empty amount of cardInput
    let flag = [];
    cardInput.map((item) => {
      if (item.Amount) {
        flag.push(item);
      }
    });

    if (flag.length > 0) {
      setLoading({ ...loading, show: true });
      previewSaveAPI(flag, (response) => {
        setLoading({ ...loading, show: false });
        setUserBarcode();
        setPage();
        flag = null;
        if (response.status === 200) {
          if (response.data.errorMessage) {
            ToastsStore.error(response.data.errorMessage);
          } else {
            setPage("preview form");
            setCardAnswer(response.data.data);
          }
        } else if (response.status === 400) {
          ToastsStore.error(response.data.errorMessage);
        }
      });
    } else {
      ToastsStore.error("مبلغ کل صفر است");
    }
  };



  const finalTransaction = () => {
    setLoading({ ...loading, show: true });
    saveTransactionAPI(cardAnswer.uniqId, (response) => {
      setLoading({ ...loading, show: false });
      if (response.status === 200) {

        sendTransactionMessageAPI(response.data.data,(res) => {
              if (res.status === 200) {
              } else if (res.status === 400) {
                ToastsStore.error(res.data.ErrorMessage);
              }
            }
        );
        if (response.data.ErrorMessage) {
          ToastsStore.error(response.data.ErrorMessage);
        } else {
          ToastsStore.success("تراکنش مورد نظر با موفقیت ثبت شد");
          setPage();
          for (let i = 0; i < 3; i++) {
            setCardInput([...cardInput], (cardInput[i].Amount = null));
          }
        }
      } else if (response.status === 400) {
        ToastsStore.error(response.data.ErrorMessage);
      }
    });
  };

  //setting input
  const settingDropDownBranch = {
    class: "input-field__transaction-registration--drop-down",
    type: "drop-down list",
    optionValue: listBranch.name,
    selectDefault: "شعبه",
    // required: true,
    // invalidClass: "input-field__transaction-registration--drop-down--alert-required"
  };
  const settingDropDownCommodityGroup = {
    class: "input-field__transaction-registration--drop-down",
    type: "drop-down list",
    optionValue: productList.parent,
    selectDefault: "گروه کالایی",
  };
  const settingDropDownCommoditySubgroupOne = {
    class: "input-field__transaction-registration--drop-down",
    type: "drop-down list",
    optionValue: productList.childOne,
    selectDefault: "زیر گروه کالایی",
  };
  const settingDropDownCommoditySubgroupTwo = {
    class: "input-field__transaction-registration--drop-down",
    type: "drop-down list",
    optionValue: productList.childTwo,
    selectDefault: "زیر گروه کالایی",
  };
  const settingDropDownCommoditySubgroupThirds = {
    class: "input-field__transaction-registration--drop-down",
    type: "drop-down list",
    optionValue: productList.childThirds,
    selectDefault: "زیر گروه کالایی",
  };
  const settingTextarea = {
    class: "input-field__transaction-registration--textarea",
    type: "textarea",
  };
  const settingText = {
    class: "input-field__transaction-registration--text",
    type: "text",
  };
  const settingPrice = {
    class: "input-field__transaction-registration--text",
    type: "tel",
  };
  const settingTextRequired = {
    class: "input-field__transaction-registration--text",
    type: "text",
    required: true,
    invalidClass: "input-field__transaction-registration--text--alert-required",
  };
  const settingCheckbox = {
    class: "input-field--checkbox",
    type: "checkbox",
    checkboxClass: "checkbox-01__transaction-registration",
  };
  //item input checkbox
  let interests = ["اعتبار دهی", "استفاده از اعتبار"];
  //input checkbox checked false || true
  const actionCheckbox = (event) => {
    if (event.value === "استفاده از اعتبار" && event.checked) {
      for (let i = 0; i < 3; i++) {
        setCardInput([...cardInput], (cardInput[i].IsusedCredit = true));
      }
    } else if (event.value === "استفاده از اعتبار" && !event.checked) {
      for (let i = 0; i < 3; i++) {
        setCardInput([...cardInput], (cardInput[i].IsusedCredit = false));
      }
    }
    // let flag
    // if (event.checked) {
    //     flag.push(event.value)
    // } else {
    //     flag.splice(flag.indexOf(event.value), 1)
    // }
  };

  //jsx form
  const jsxForm = (
    <>
      <BarcodeScanner
        title="ثبت تراکنش"
        color="var(--transaction-registration)"
        actionBack={props.history}
        receiveBarcode={setUserBarcode}
      />
      <Loading loading={loading.show} message={loading.message} />
      <div className="transaction-registration unselectable">
        <div className="transaction-registration__card">
          <p className="transaction-registration__card__text">
            اعتبار فعلی : {card.dedicatedCredit ? card.dedicatedCredit : ""}
          </p>
          <p className="transaction-registration__card__text">
            شماره سریال : {card.cardSerialNumber ? card.cardSerialNumber : ""}
          </p>
          <p className="transaction-registration__card__text">
            شماره همراه مشتری : {card.cellPhone ? card.cellPhone : ""}
          </p>
          <p className="transaction-registration__card__text">
            نام و نام خانوادگی :{" "}
            {card.fristName ? `${card.fristName} ${card.lastName}` : ""}
          </p>
          <p className="transaction-registration__card__text">
            امتیاز فعلی : {card.point ? card.point : ""}
          </p>
        </div>
        <form
          className="transaction-registration__form"
          onSubmit={(event) => sendForm(event)}
        >
          <div className="transaction-registration__form__group-input">
            <div className="input-field__checkbox--group">
              {interests.map((item, index) => (
                <Checkbox
                  key={index}
                  setting={settingCheckbox}
                  labelAndValue={[{ label: item, value: item }]}
                  target={actionCheckbox}
                />
              ))}
            </div>
            {listBranch.name.length > 0 ? (
              <Input
                setting={settingDropDownBranch}
                setValue={(event) => convertBranchToId(event)}
              />
            ) : null}
            <div className="transaction-registration__form__group-input__group">
              <Input
                setting={settingPrice}
                placeholder="مبلغ فروش"
                setValue={(event) =>
                  setCardInput([...cardInput], (cardInput[0].Amount = event))
                }
              />
              <Input
                setting={settingDropDownCommodityGroup}
                setValue={(event) => makerChild(event, "One", 0)}
              />
              <Input
                setting={settingDropDownCommoditySubgroupOne}
                setValue={(event) => convertChildToId(event, 0)}
              />
            </div>
            <div className="transaction-registration__form__group-input__group">
              <Input
                setting={settingPrice}
                placeholder="مبلغ فروش"
                setValue={(event) =>
                  setCardInput([...cardInput], (cardInput[1].Amount = event))
                }
              />
              <Input
                setting={settingDropDownCommodityGroup}
                setValue={(event) => makerChild(event, "Two", 1)}
              />
              <Input
                setting={settingDropDownCommoditySubgroupTwo}
                setValue={(event) => convertChildToId(event, 1)}
              />
            </div>
            <div className="transaction-registration__form__group-input__group">
              <Input
                setting={settingPrice}
                placeholder="مبلغ فروش"
                setValue={(event) =>
                  setCardInput([...cardInput], (cardInput[2].Amount = event))
                }
              />
              <Input
                setting={settingDropDownCommodityGroup}
                setValue={(event) => makerChild(event, "Thirds", 2)}
              />
              <Input
                setting={settingDropDownCommoditySubgroupThirds}
                setValue={(event) => convertChildToId(event, 2)}
              />
            </div>
            <Input
              setting={settingText}
              placeholder="شماره فاکتور"
              setValue={(event) => invoiceNumberToAllCard(event)}
            />
            <Input
              setting={settingTextarea}
              placeholder="متن این تراکنش"
              setValue={(event) => textOfTransactionToAllCard(event)}
            />
          </div>
          <div className="transaction-registration__form__button">
            <div className="transaction-registration__form__button--success">
              <input type="submit" value="پیش نمایش و تایید" />
            </div>
            <div
              onClick={() => {
                setPage();
                setUserBarcode();
                for (let i = 0; i < 3; i++) {
                  setCardInput([...cardInput], (cardInput[i].Amount = null));
                }
              }}
              className="transaction-registration__form__button--cancel"
            >
              <span>انصراف</span>
            </div>
          </div>
        </form>
      </div>
    </>
  );

  //jsx preview form
  const [showMenu, setShowMenu] = useState(false); //it is related to components menu
  const jsxPreviewForm = (
    <>
      <Loading loading={loading.show} message={loading.message} />
      <div className="barcode-scanner-container unselectable">
        <Menu show={showMenu} hide={() => setShowMenu(false)} />
        <ActionBar
          title="ثبت تراکنش"
          color="var(--transaction-registration)"
          leftIcon={back}
          leftClick={() => setPage()}
          rightIcon={menu}
          rightClick={() => setShowMenu(!showMenu)}
        />
      </div>
      <div className="transaction-registration__preview-form unselectable">
        <div className="transaction-registration__preview-form__gray__full-background">
          <div className="transaction-registration__preview-form__gray">
            <div className="transaction-registration__preview-form__gray__group">
              <span className="transaction-registration__preview-form__gray--title">
                شماره مشتری
              </span>
              <span className="transaction-registration__preview-form__gray--text">
                {card.cellPhone ? card.cellPhone : ""}
              </span>
            </div>
            <div className="transaction-registration__preview-form__gray__group">
              <span className="transaction-registration__preview-form__gray--title">
                اعتبار مشتری
              </span>
              <span className="transaction-registration__preview-form__gray--text">
                {cardAnswer.customerCredit}
              </span>
            </div>
          </div>
        </div>
        <div className="transaction-registration__preview-form__gray__full-background">
          <h4 className="transaction-registration__preview-form__red__title">
            اطلاعات مربوط به پرداخت
          </h4>
        </div>
        <div className="transaction-registration__preview-form__red__full-background">
          <div className="transaction-registration__preview-form__red">
            <div className="transaction-registration__preview-form__red__group">
              <div className="transaction-registration__preview-form__red__group__one">
                <div className="transaction-registration__preview-form__red__group__one_content">
                  <span className="transaction-registration__preview-form__red__group__one_content--title">
                    مبلغ فروش
                  </span>
                  <span className="transaction-registration__preview-form__red__group__one_content--text">
                    {cardAnswer.shoppingAmount}
                  </span>
                </div>
                <div className="transaction-registration__preview-form__red__group__one_content">
                  <span className="transaction-registration__preview-form__red__group__one_content--title">
                    اعتبار
                  </span>
                  <span className="transaction-registration__preview-form__red__group__one_content--text">
                    {cardAnswer.usingCredit}
                  </span>
                </div>
                <div className="transaction-registration__preview-form__red__group__one_content">
                  <span className="transaction-registration__preview-form__red__group__one_content--title">
                    تخفیف
                  </span>
                  <span className="transaction-registration__preview-form__red__group__one_content--text">
                    {cardAnswer.discountOffer}
                  </span>
                </div>
              </div>
              <span className="transaction-registration__preview-form__red__group__two"></span>
            </div>
            <div className="transaction-registration__preview-form__red__wide">
              <span className="transaction-registration__preview-form__red__wide--title">
                مبلغ قابل پرداخت :
              </span>
              <span className="transaction-registration__preview-form__red__wide--text">
                {cardAnswer.payableAmount}
              </span>
            </div>
          </div>
        </div>
        <div className="transaction-registration__preview-form__gray__full-background">
          <h4 className="transaction-registration__preview-form__red__title">
            اطلاعات مربوط به اعتبار
          </h4>
        </div>
        <div className="transaction-registration__preview-form__red__full-background">
          <div className="transaction-registration__preview-form__red">
            <div className="transaction-registration__preview-form__red__group">
              <div className="transaction-registration__preview-form__red__group__one">
                <div className="transaction-registration__preview-form__red__group__one_content">
                  <span className="transaction-registration__preview-form__red__group__one_content--title">
                    مانده اعتبار
                  </span>
                  <span className="transaction-registration__preview-form__red__group__one_content--text">
                    {cardAnswer.remaningCredit}
                  </span>
                </div>
                <div className="transaction-registration__preview-form__red__group__one_content">
                  <span className="transaction-registration__preview-form__red__group__one_content--title">
                    اعتبار خرید
                  </span>
                  <span className="transaction-registration__preview-form__red__group__one_content--text">
                    {cardAnswer.newCredit}
                  </span>
                </div>
                <div className="transaction-registration__preview-form__red__group__one_content">
                  <span className="transaction-registration__preview-form__red__group__one_content--title">
                    اعتبار آفر
                  </span>
                  <span className="transaction-registration__preview-form__red__group__one_content--text">
                    {cardAnswer.offerCredit}
                  </span>
                </div>
              </div>
              <span className="transaction-registration__preview-form__red__group__two"></span>
            </div>
            <div className="transaction-registration__preview-form__red__wide">
              <span className="transaction-registration__preview-form__red__wide--title">
                مجموع اعتبار :
              </span>
              <span className="transaction-registration__preview-form__red__wide--text">
                {cardAnswer.newSumCredit}
              </span>
            </div>
          </div>
        </div>
        <div className="transaction-registration__form__button">
          <div
            onClick={finalTransaction}
            className="transaction-registration__form__button--success"
          >
            <span>ثبت و تایید نهایی</span>
          </div>
          <div
            onClick={() => {
              setPage();
              for (let i = 0; i < 3; i++) {
                setCardInput([...cardInput], (cardInput[i].Amount = null));
              }
            }}
            className="transaction-registration__form__button--cancel"
          >
            <span>انصراف</span>
          </div>
        </div>
      </div>
    </>
  );

  if (localStorage.getItem("token") != null)
    switch (page) {
      case "form":
        return jsxForm;
      case "preview form":
        return jsxPreviewForm;
      default:
        return (
          <BarcodeScanner
            title="ثبت تراکنش"
            color="var(--transaction-registration)"
            actionBack={props.history}
            receiveBarcode={setUserBarcode}
          />
        );
    }
  else return <Redirect to="/login" />;
}

export default TransactionRegistration;
