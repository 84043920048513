import React, {Fragment, useState} from 'react';
import "./Input.css"

function Input(props) {

    const [pickerModal, setPickerModal] = useState({show: false, selected: ""});

    const validation = (value) => {
        if (!props.disable) {
            switch (props.type) {
                case "mobile":
                    const numberRegex = RegExp(/^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/g);
                    if (value.length === 0 || numberRegex.test(value.replace(/,/g,''))) {
                        props.setValue(value);
                    }
                    break;
                case "password":
                    props.setValue(value);
                    break;
                default:
                    props.setValue(value);
            }
        }
    };

    const getContent = () => {
        switch (props.type) {
            case "mobile":
                return (
                    <input disabled={props.disable} type="tel" placeholder={props.placeholder} value={props.value}
                           onChange={(e) => validation(e.target.value)} style={props.style}/>
                );
            case "password":
                return (
                    <input disabled={props.disable} type="password" placeholder={props.placeholder} value={props.value}
                           onChange={(e) => validation(e.target.value)}/>
                );
            case "textarea":
                return (
                    <textarea disabled={props.disable} placeholder={props.placeholder} value={props.value}
                              onChange={(e) => validation(e.target.value)} rows={props.rows}/>
                );
            case "gender":
                return (
                    <div className="gender">
                        <label className="container" onClick={()=> props.setValue("male")}>مرد
                            <input type="checkbox" checked={props.value === "male"}/>
                                <span className="checkmark"/>
                        </label>
                        <label className="container" onClick={()=> props.setValue("female")}>زن
                            <input type="checkbox"  checked={props.value === "female"}/>
                            <span className="checkmark"/>
                        </label>
                    </div>
                );
            case "drop-down":
                return (
                    <button onClick={() => setPickerModal({show: true, selected: props.value})}>{props.value.length === 0 ? "یک گزینه را انتخاب کنید" : props.items.find((item)=> item.id === props.value).name}</button>
                );
            default:
                return (
                    <input disabled={props.disable} type="text" placeholder={props.placeholder} value={props.value}
                           onChange={(e) => validation(e.target.value)}/>
                );
        }
    };

    return (
        <Fragment>
            <div className={"input-field " + props.class}>
                <label>{props.label}</label>
                {getContent()}
            </div>
            {pickerModal.show &&
            <div className="drop-down-modal">
                <div>
                    <div>
                        {props.items.map((item) => (
                            <span onClick={()=>setPickerModal({...pickerModal, selected: item.id})}
                                  className={pickerModal.selected === item.id && "selected"}>{item.name}</span>
                        ))}
                    </div>
                    <button onClick={()=> {
                        setPickerModal({...pickerModal, show: false});
                        props.setValue(pickerModal.selected);
                    }
                    }>تایید</button>
                </div>
            </div>
            }
        </Fragment>
    );
}

export default Input;
