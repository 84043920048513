export const priceFormat = (num) => {
    const id = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    var number = typeof num === "number" ? num.toString() : num;
    return number.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + ',').replace(/[0-9]/g, w => id[+w]);
};

export const toPersian = (num) => {
    const id = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    return num.replace(/[0-9]/g, w => id[+w]);
};

export const toEnglish = (string) => {
    const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
          arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
    if(typeof string === 'string')
    {
        for(var i=0; i<10; i++) {
            string = string.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
        }
    }
    return string;
};

export const convertDate = (year, month, day) => {
    if (year.length === 0 || month.length === 0 || day.length === 0){
        return "";
    }
    let date = year;
    switch (month) {
        case "فروردین":
            date = date + "/01/";
            break;
        case "اردیبهشت":
            date = date + "/02/";
            break;
        case "خرداد":
            date = date + "/03/";
            break;
        case "تیر":
            date = date + "/04/";
            break;
        case "امرداد":
            date = date + "/05/";
            break;
        case "شهریور":
            date = date + "/06/";
            break;
        case "مهر":
            date = date + "/07/";
            break;
        case "آبان":
            date = date + "/08/";
            break;
        case "آذر":
            date = date + "/09/";
            break;
        case "دی":
            date = date + "/10/";
            break;
        case "بهمن":
            date = date + "/11/";
            break;
        case "اسفند":
            date = date + "/12/";
            break;
    }
    return date + (day.length === 1 ? "0" + day : day);
};

export const getMonth = (date) => {
    const month = date.split("/")[1];
    switch (month) {
        case "01":
        return "فروردین";
        case "02":
        return "اردیبهشت";
        case "03":
        return "خرداد";
        case "04":
        return "تیر";
        case "05":
        return "امرداد";
        case "06":
        return "شهریور";
        case "07":
        return "مهر";
        case "08":
        return "آبان";
        case "09":
        return "آذر";
        case "10":
        return "دی";
        case "11":
        return "بهمن";
        case "12":
        return "اسفند";
    }
};

export const getDay = (date) => {
    const day = date.split("/")[2];
    switch (day) {
        case "01":
        return "1";
        case "02":
        return "2";
        case "03":
        return "3";
        case "04":
        return "4";
        case "05":
        return "5";
        case "06":
        return "6";
        case "07":
        return "7";
        case "08":
        return "8";
        case "09":
        return "9";
    }
    return day;
};