import React from 'react';
import './ActionBar.css';


function ActionBar(props) {

    return (
        <div className="action-bar" style={{backgroundColor: props.color}}>
            {props.rightIcon ? <img className="right-icon" alt="logo" src={props.rightIcon} onClick={props.rightClick}/> : <span/>}
            {props.logo ?
                <img className="logo" src={props.logo}/>
            :
                <h1>{props.title}</h1>
            }
            {props.leftIcon ? <img className="left-icon" alt="left-icon" src={props.leftIcon} onClick={props.leftClick}/> : <span/>}
        </div>
    );
}

export default ActionBar;
