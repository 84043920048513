import React from 'react';
import { ToastsStore } from "react-toasts";
import './Input.css';

function Input(props) {

    //action empty & invalid input
    const invalid = (event) => {
        event.target.className = props.setting.invalidClass;
    };

    //check value input based on RegExp
    const validation = (event) => {
        if (props.setting.pattern) {
            switch (props.setting.pattern) {
                case "number":
                    if (event.target.value.length === 0 || /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/g.test(event.target.value)) {
                        props.setValue(event.target.value)
                        event.target.className = ""
                    } else {
                        event.target.value = null
                        invalid(event)
                    }
                    break;
                case "phone":
                    //if (/^(0)-?\d{0,10}$/g.test(event.target.value)) {
                    if (event.target.value.length === 0 || /^(0)-?\d{0,}$/g.test(event.target.value)) {
                        props.setValue(event.target.value)
                        event.target.className = ""
                    } else {
                        event.target.value = null
                        invalid(event)
                    }
                    break;
            }
        } else {
            props.setValue(event.target.value)
            event.target.className = ""
        }
    };

    //check volume file upload
    const validationFile = (event) => {
        if (props.setting.volumeLimit) {
            for (let i = 0; i < event.target.files.length; i++) {
                if (event.target.files[i].size <= props.setting.volumeLimit) {
                    props.setValue(event.target.files[i])
                    event.target.className = ""
                } else {
                    ToastsStore.error(props.error);
                    event.target.value = null
                    invalid(event)
                }
            }
        } else {
            props.setValue(event.target.files[0])
            event.target.className = ""
        }
    };

    //validation drop-down list multiple select
    const validationMultiple = (event) => {
        props.setValue(event.target.options)
        event.target.className = ""
    }

    //generate input
    const generateInput = () => {
        if (props.setting.required) {
            switch (props.setting.type) {
                //required field
                case "textarea":
                    return (
                        <textarea value={props.value} placeholder={props.placeholder} title={props.title} onChange={validation} onInvalid={invalid} required />
                    );
                case "drop-down list":
                    if (props.setting.multiple) {
                        return (
                            <select value={props.value} onChange={validationMultiple} multiple onInvalid={invalid} required>
                                <option value="" selected disabled>{props.setting.selectDefault}</option>
                                {props.setting.optionValue.map((item, index) =>
                                    <option value={item} key={index}>{item}</option>
                                )}
                            </select>
                        );
                    } else {
                        return (
                            <select value={props.value} onChange={validation} onInvalid={invalid} required>
                                <option value="" selected disabled>{props.setting.selectDefault}</option>
                                {props.setting.optionValue.map((item, index) =>
                                    <option value={item} key={index}>{item}</option>
                                )}
                            </select>
                        );
                    }
                case "radio":
                    return (
                        <div className={props.setting.radioClass}>
                            {props.setting.labelAndValue.map((item, index) =>
                                <label key={index}>{item.label}
                                    <input value={item.value} type={props.setting.type} name={props.name} onChange={validation} onInvalid={invalid} required />
                                </label>
                            )}
                        </div>
                    );
                case "checkbox":
                    //customize for Step_9 (labelAndValue)
                    return (
                        <div className={props.setting.checkboxClass}>
                            {props.labelAndValue.map((item, index) =>
                                <label key={index}>{item.label}
                                    <input value={item.value} type={props.setting.type} name={props.name} onChange={validation} onInvalid={invalid} required />
                                </label>
                            )}
                        </div>
                    );
                case "file":
                    if (props.setting.multiple) {
                        return (
                            <input type={props.setting.type} accept={props.accept} onChange={validationFile} onInvalid={invalid} required multiple />
                        );
                    } else {
                        return (
                            <input type={props.setting.type} accept={props.accept} onChange={validationFile} onInvalid={invalid} required />
                        );
                    }
                default:
                    if (props.disabled) {
                        return (
                            <input value={props.value} type={props.setting.type} title={props.title} disabled />
                        );
                    } else {
                        return (
                            props.focus
                                ?
                                <input autoFocus value={props.value} type={props.setting.type} placeholder={props.placeholder} title={props.title}
                                    onChange={validation} onInvalid={invalid} required />
                                :
                                <input value={props.value} type={props.setting.type} placeholder={props.placeholder} title={props.title}
                                    onChange={validation} onInvalid={invalid} required />

                        );
                    }
            }
        } else {
            switch (props.setting.type) {
                //normal field
                case "textarea":
                    return (
                        <textarea value={props.value} placeholder={props.placeholder} title={props.title} onChange={validation} />
                    );
                case "drop-down list":
                    if (props.setting.multiple) {
                        return (
                            <select value={props.value} onChange={validationMultiple} multiple >
                                <option value="" selected disabled>{props.setting.selectDefault}</option>
                                {props.setting.optionValue.map((item, index) =>
                                    <option value={item} key={index}>{item}</option>
                                )}
                            </select>
                        );
                    } else {
                        return (
                            <select value={props.value} onChange={validation} >
                                <option value="" selected disabled>{props.setting.selectDefault}</option>
                                {props.setting.optionValue.map((item, index) =>
                                    <option value={item} key={index}>{item}</option>
                                )}
                            </select>
                        );
                    }
                case "radio":
                    return (
                        <div className={props.setting.radioClass}>
                            {props.setting.labelAndValue.map((item, index) =>
                                <label key={index} className={props.name}>{item.label}
                                    <input value={item.value} type={props.setting.type} name={props.name} onChange={validation} />
                                </label>
                            )}
                        </div>
                    );
                case "checkbox":
                    //customize for Step_9 (labelAndValue)
                    return (
                        <div className={props.setting.checkboxClass}>
                            {props.labelAndValue.map((item, index) =>
                                <label key={index}>{item.label}
                                    <input value={item.value} type={props.setting.type} name={props.name} onChange={validation} />
                                </label>
                            )}
                        </div>
                    );
                case "file":
                    if (props.setting.multiple) {
                        return (
                            <input type={props.setting.type} accept={props.accept} onChange={validationFile} multiple />
                        );
                    } else {
                        return (
                            <input type={props.setting.type} accept={props.accept} onChange={validationFile} />
                        );
                    }
                default:
                    if (props.disabled) {
                        return (
                            <input value={props.value} type={props.setting.type} title={props.title} disabled />
                        );
                    } else {
                        return (
                            props.focus
                                ?
                                <input autoFocus value={props.value} type={props.setting.type} placeholder={props.placeholder} title={props.title}
                                    onChange={validation} />
                                :
                                <input value={props.value} type={props.setting.type} placeholder={props.placeholder} title={props.title}
                                    onChange={validation} />
                        );
                    }
            }
        }
    };

    return (
        <div className={props.setting.class}>
            <label>{props.label}</label>
            {generateInput()}
        </div>
    )

}

export default Input;