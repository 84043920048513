import React, {Fragment, useState, useEffect} from 'react';
import changeOperatorIcon from '../../assets/images/Menu/change-operator.svg';
import logoutIcon from '../../assets/images/Menu/logout.svg';
import './Menu.css';
import {withRouter} from "react-router-dom";
import {getHomePageDataAPI} from "../../api/api";


function Menu(props) {

    const [unregisterModal, showUnregisterModal] = useState(false);

    const [data, setData] = useState({
        FristName: "",
        LastName:"",
        CellPhone: "",
        Id: "",
        TransactionCount: "",
        MemebersCount: ""
    });

    // useEffect(()=>{
    //     if (localStorage.getItem('token') != null) {
    //         getHomePageDataAPI(response => {
    //             if (response.data.status === 200) {
    //                 setData({
    //                     FirstName: response.data.data.FirstName,
    //                     LastName: response.data.data.LastName,
    //                     CellPhone: response.data.data.CellPhone,
    //                     MerchantId: response.data.data.MerchantId,
    //                     TransactionCount: response.data.data.TransactionCount,
    //                     MembersCount: response.data.data.MembersCount
    //                 })
    //             }
    //         })
    //     }
    // },[localStorage.getItem('token')]);

    useEffect(()=>{
        if (localStorage.getItem('userAccountInfo')) {
           setData(JSON.parse(localStorage.getItem('userAccountInfo')));
        }
    },[localStorage.getItem('userAccountInfo')]);


    return (
        <Fragment>
            <div className="menu" style={{width: (props.show ? "100%" : "0")}}>
                <div className="menu-container">
                    <div className="menu-header">
                        <span>{data.FristName} {data.LastName}</span>
                        <span>{data.CellPhone}</span>
                        <br/>
                        {data.Id && <span>کد عضویت: {data.Id}</span>}
                        {data.TransactionCount && <span>تعداد فروش: {data.TransactionCount}</span>}
                        {data.MemebersCount && <span>تعداد اعضا: {data.MemebersCount}</span>}
                    </div>
                    <div className="menu-body">
                        <span onClick={() => {}}><img src={changeOperatorIcon}/>تغییر اپراتور</span>
                        <hr/>
                        <span onClick={() => {
                            if (localStorage.getItem("token")){
                                localStorage.removeItem("token");
                                localStorage.removeItem("userAccountInfo");
                                window.location.reload()
                                window.location.href = "/login"
                            } else {
                                props.hide();
                                showUnregisterModal(true);
                            }
                        }}><img src={logoutIcon}/>خروج</span>
                    </div>
                </div>
                <div className="close-menu" onClick={props.hide}/>
            </div>
            {unregisterModal &&
                <div className="unregister-modal">
                    <div>
                        <p>برای استفاده از امکانات اپلیکیشن وین کوین ابتدا باید وارد شوید.</p>
                        <button onClick={() => showUnregisterModal(false)}>تایید</button>
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default withRouter(Menu);
