import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import BarcodeScanner from "../../components/BarcodeScanner/BarcodeScanner";
import Input from "../../components/InputTwo/Input/Input";
import "./IncreaseCredit.css";
import Loading from "../../components/Loading/Loading";
import { ToastsStore } from "react-toasts";
import {
  getCustomerInfoBySerialNoAPI,
  chargGiftCardAPI,
  getCustomerInfoByPhoneNoAPI,
} from "../../api/api";

function IncreaseCredit(props) {
  const [loading, setLoading] = useState({ show: false, message: "" });

  //get the barcode number written by the user in components/BarcodeScanner
  const [userBarcode, setUserBarcode] = useState();

  const [showForm,setShowForm] = useState(false)

  //get information from API
  const [card, setCard] = useState({
    cardSerialNumber: "",
    remainCredit: "",
    cellPhone: "",
    fristName: "",
    lastName: "",
    point: "",
  });

  const [form, setForm] = useState({
    Amount: "",
    SerialNo: "",
    merchantTypeId: "",
    MerchantId: "",
  });

  useEffect(() => {
    if (userBarcode) {
      setLoading({ ...loading, show: true });
      setCard({});
      if (userBarcode.length === 16) {
       

        //get info customer from API
        getCustomerInfoBySerialNoAPI(
          userBarcode,
          JSON.parse(localStorage.getItem("userAccountInfo")).Id,
          (response) => {
            setLoading({ ...loading, show: false });
            if (response.status === 200) {
              if (response.data.data) {
                  setShowForm(true);
                  setForm({
                    Amount: "",
                    SerialNo: response.data.data?.cardSerialNumber,
                    MerchantTypeId: JSON.parse(localStorage.getItem("userAccountInfo"))
                      .MerchantTypeId,
                    MerchantId: JSON.parse(localStorage.getItem("userAccountInfo")).Id,
                  });
                setCard(response.data.data);
              } else {
                setShowForm(false);

                ToastsStore.error(response.data.errorMessage);
              }
            } else if (response.status === 400) {
                setShowForm(false);

              ToastsStore.error(response.data.errorMessage);
            }
          }
        );
      } else {
        //get info customer from API (phone)
        getCustomerInfoByPhoneNoAPI(
          userBarcode,
          JSON.parse(localStorage.getItem("userAccountInfo")).Id,
          (response) => {
            setLoading({ ...loading, show: false });
            if (response.status === 200) {
              if (response.data.data) {
                setShowForm(true);
                  setForm({
                    Amount: "",
                    SerialNo: response.data.data?.cardSerialNumber,
                    MerchantTypeId: JSON.parse(localStorage.getItem("userAccountInfo"))
                      .MerchantTypeId,
                    MerchantId: JSON.parse(localStorage.getItem("userAccountInfo")).Id,
                  });
                  setCard(response.data.data);
              } else {
                setShowForm(false);

                ToastsStore.error(response.data.errorMessage);
              }
            } else if (response.status === 400) {
                setShowForm(false);

              ToastsStore.error(response.data.errorMessage);
            }
          }
        );
      }
    }
  }, [userBarcode]);

  const sendForm = (event) => {
    event.preventDefault();
    setLoading({ ...loading, show: true });
    chargGiftCardAPI(
      form.Amount,
      form.SerialNo,
      form.MerchantTypeId,
      form.MerchantId,
      (response) => {
        setLoading({ ...loading, show: false });
        setUserBarcode();
        if (response.status === 200) {
          response.data.errorMessage
            ? ToastsStore.error(response.data.errorMessage)
            : ToastsStore.success("افزایش اعتبار با موفقیت انجام شد");
        } else if (response.status === 400) {
          ToastsStore.error(response.data.errorMessage);
        }
      }
    );
  };

  //setting input
  const settingTextRequired = {
    class: "input-field__increase-credit--text",
    type: "text",
    required: true,
    invalidClass: "input-field__increase-credit--text--alert-required",
  };

  if (localStorage.getItem("token") != null)
    return (
      <>
        <BarcodeScanner
          title="افزایش اعتبار"
          color="var(--increase-credit)"
          actionBack={props.history}
          receiveBarcode={setUserBarcode}
        />
            <Loading loading={loading.show} message={loading.message} />

        {showForm ? (
          <div className="Increase-credit-container unselectable">
            <div className="Increase-credit-container__card">
              <p className="Increase-credit-container__card__text">
                اعتبار قابل برداشت :{" "}
                {card.remainCredit ? card.remainCredit : ""}
              </p>
              <p className="Increase-credit-container__card__text">
                شماره سریال :{" "}
                {card.cardSerialNumber ? card.cardSerialNumber : ""}
              </p>
              <p className="Increase-credit-container__card__text">
                شماره همراه مشتری : {card.cellPhone ? card.cellPhone : ""}
              </p>
              <p className="Increase-credit-container__card__text">
                نام و نام خانوادگی :{" "}
                {card.fristName ? `${card.fristName} ${card.lastName}` : ""}
              </p>
              <p className="Increase-credit-container__card__text">
                امتیاز فعلی : {card.point ? card.point : ""}
              </p>
            </div>
            <form
              className="Increase-credit-container__form"
              onSubmit={(event) => sendForm(event)}
            >
              <div className="Increase-credit-container__form__group-input">
                <Input
                  setting={settingTextRequired}
                  label="میزان افزایش اعتبار"
                  setValue={(event) => setForm({ ...form, Amount: event })}
                />
              </div>
              <div className="Increase-credit-container__form__button">
                <div className="Increase-credit-container__form__button--success">
                  <input type="submit" value="افزایش اعتبار" />
                </div>
                <div
                  onClick={() => setUserBarcode(false)}
                  className="Increase-credit-container__form__button--cancel"
                >
                  <span>انصراف</span>
                </div>
              </div>
            </form>
          </div>
        ) : null}
      </>
    );
  else return <Redirect to="/login" />;
}

export default IncreaseCredit;
