import React, {Fragment, useEffect, useState} from 'react';
import './Home.css';
import ActionBar from "../../components/ActionBar/ActionBar";
import menu from "../../assets/images/Menu/menu.svg";
import logo from "../../assets/images/logo.png";
import Menu from "../../components/Menu/Menu";
import noImage from "../../assets/images/no-image.png";
import {Link, Redirect} from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import {ToastsStore} from "react-toasts";


function Home(props) {

    const [showMenu, setShowMenu] = useState(false);
    const [loading, setLoading] = useState({show: false, message: "در حال بارگذاری"});
    const [data, setData] = useState({
        // Logo: noImage,
        // brandName: "فروشگاه بزرگ پیروز",
        // MembersCount: "2346364",
        // TransactionCount: "523523523",
        // hajmefroush: "23525323",
        // etebareSadere: "3523523",
        // etebareMasrafShode: "35235235",
        // PercentLoyalty: "2532352",
        // MerchantId: 1
    });

    // useEffect(()=>{
    //     getHomePageDataAPI(response => {
    //         setLoading({...loading, show: false});
    //         if (response.data.status === 200) {
    //             setHomePageData({
    //                 FristName: response.data.data.FristName,
    //                 LastName: response.data.data.LastName,
    //                 CellPhone: response.data.data.CellPhone,
    //                 CreateDateShamsi: response.data.data.CreateDateShamsi,
    //                 ProfilePercent: response.data.data.ProfilePercent,
    //                 CustomerPoint: response.data.data.CustomerPoint,
    //                 DedicatedCredit: response.data.data.DedicatedCredit,
    //                 MerchantName: response.data.data.MerchantName,
    //                 Logo: (response.data.data.Logo ? "data:image/png;base64, " + response.data.data.Logo : noImage),
    //                 Color: response.data.data.Color,
    //                 MerchantId: response.data.data.MerchantId,
    //             })
    //         } else if (response.data.status === 400) {
    //             ToastsStore.error(response.data.errorMessage);
    //         }
    //     })
    // },[]);


    useEffect(()=>{
        if (localStorage.getItem('userAccountInfo')) {
           setData(JSON.parse(localStorage.getItem('userAccountInfo')));
        }
    },[localStorage.getItem('userAccountInfo')]);

    if (localStorage.getItem("token") != null)
        return (
            <Fragment>
                <Loading loading={loading.show} message={loading.message}/>
                <div className="home-container unselectable">
                    <Menu show={showMenu} hide={() => setShowMenu(false)}/>
                    <ActionBar logo={data.Logo ? `data:image/png;base64,${data.Logo}` : noImage} rightIcon={menu} rightClick={() => setShowMenu(!showMenu)}/>
                    <div className="content">
                        <img src={data.Logo ? `data:image/png;base64,${data.Logo}` : noImage}/>
                        <span>{data.BrandName ? data.BrandName : ''}</span>
                        <div className="content__info-box">
                            <div>
                                <span>تعداد اعضاء</span>
                                <strong>{data.MemebersCount ? data.MemebersCount : ''}</strong>
                            </div>
                            <div>
                                <span>تعداد فروش</span>
                                <strong>{data.TransactionCount ? data.TransactionCount : ''}</strong>
                            </div>
                            <div>
                                <span>درصد وفاداری</span>
                                <strong>{data.PercentLoyalty ? data.PercentLoyalty : ''}</strong>
                            </div>
                        </div>
                        <div className="content__links">
                            <Link className="content__links__link content__links__link--1" to="/membership">
                                <img src={require("../../assets/images/Home/register.png")}/>
                                <span>عضویت</span>
                            </Link>
                            <Link className="content__links__link content__links__link--2" to="/transaction-registration">
                                <img src={require("../../assets/images/Home/add-transaction.png")}/>
                                <span>ثبت تراکنش</span>
                            </Link>
                            <Link className="content__links__link content__links__link--3" to="/decrease-credit">
                                <img src={require("../../assets/images/Home/decrease-credit.png")}/>
                                <span>کسر از اعتبار</span>
                            </Link>
                            <Link className="content__links__link content__links__link--4" to="/increase-credit">
                                <img src={require("../../assets/images/Home/increase-credit.png")}/>
                                <span>افزایش اعتبار</span>
                            </Link>
                            <Link className="content__links__link content__links__link--5" to="/change-card">
                                <img src={require("../../assets/images/Home/change-card.png")}/>
                                <span>تغییر کارت</span>
                            </Link>
                            <Link className="content__links__link content__links__link--6" to="">
                                <img src={require("../../assets/images/Home/report.png")}/>
                                <span>گزارشات</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    else
        return <Redirect to="/login"/>
}

export default Home;
