import React, {Fragment, useState} from 'react';
import './ForgetPassword.css';
import Menu from "../../components/Menu/Menu";
import ActionBar from "../../components/ActionBar/ActionBar";
import back from "../../assets/images/back.svg";
import menu from "../../assets/images/Menu/menu.svg";
import Input from "../../components/Input/Input";
import {ToastsStore} from 'react-toasts';
import logo from "../../assets/images/logo.png";
import {getActivationCodeAPI} from "../../api/api";
import Loading from "../../components/Loading/Loading";
import {Redirect} from "react-router-dom";
import { forgetPasswordAPI } from "../../api/api";


function ForgetPassword(props) {

    const [loading, setLoading] = useState({show: false, message: ""});
    const [showMenu, setShowMenu] = useState(false);
    const [mobile, setMobile] = useState("");

    const getVerificationCode = () => {
        const phoneNum = /^(9|09)(12|19|30|33|35|36|37|38|39|32|21|03|02|04|05|41|31|34|01|10|11|13|14|15|16|17|18|90|91|92)\d{7}$/;
        if (!mobile.match(phoneNum)) {
            ToastsStore.error("شماره موبایل معتبر نیست");
        } else {
            setLoading({show: true, message: "در حال بررسی شماره"});
            // getActivationCodeAPI(mobile, 2, response => {
            //     setLoading({...loading, show: false});
            //     if (response.data.status === 200) {
            //         ToastsStore.error(response.data.errorMessage);
            //     } else if (response.data.status === 400) {
            //         ToastsStore.error(response.data.errorMessage);
            //     }
            // });
            forgetPasswordAPI(mobile, response => {
                setLoading({ ...loading, show: false });
                if (response.status === 200) {
                    ToastsStore.error(response.data.errorMessage);
                } else if (response.status === 400) {
                    ToastsStore.error(response.data.errorMessage);
                }
            });
        }
    };

    if (localStorage.getItem('token') != null && localStorage.getItem('userAccountInfo')) {
        return (
            <Redirect to='/'/>
        );
    } else {
        return (
            <div className="forget-password-container unselectable">
                <Loading loading={loading.show} message={loading.message}/>
                <Menu show={showMenu} hide={() => setShowMenu(false)}/>
                <ActionBar logo={logo} leftIcon={back} leftClick={() => props.history.goBack()}
                           rightIcon={menu} rightClick={() => setShowMenu(!showMenu)}/>
                <div className="forget-password-content">
                    <Fragment>
                        <p>شماره همراه (نام کاربری) خود را وارد کنید</p>
                        <Input class="red-label" label="نام کاربری" type="mobile" placeholder=""
                               value={mobile}
                               setValue={(value) => setMobile(value)}/>
                        <button onClick={getVerificationCode}>تائید</button>
                    </Fragment>
                </div>
            </div>
        );
    }
}

export default ForgetPassword;
