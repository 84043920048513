import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from "react-toasts";
import Login from "../containers/Login/Login";
import ForgetPassword from "../containers/ForgetPassword/ForgetPassword";
import Home from "../containers/Home/Home";
import AddToHomeScreen from "../components/AddToHomeScreen/AddToHomeScreen";
import Membership from '../containers/Membership/Membership';
import TransactionRegistration from '../containers/TransactionRegistration/TransactionRegistration';
import IncreaseCredit from '../containers/IncreaseCredit/IncreaseCredit';
import DecreaseCredit from '../containers/DecreaseCredit/DecreaseCredit';
import ChangeCard from '../containers/ChangeCard/ChangeCard';
import CacheBuster from '../components/CacheBuster';


const Routes = () => (
    <BrowserRouter>
        <CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    refreshCacheAndReload();
                }
                return (
                    <>
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/login' component={Login} />
                            <Route exact path='/forget-password' component={ForgetPassword} />
                            <Route exact path='/membership' component={Membership} />
                            <Route exact path='/transaction-registration' component={TransactionRegistration} />
                            <Route exact path='/increase-credit' component={IncreaseCredit} />
                            <Route exact path='/decrease-credit' component={DecreaseCredit} />
                            <Route exact path='/change-card' component={ChangeCard} />
                            <Route component={Home} />
                        </Switch>
                        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} lightBackground />
                        <AddToHomeScreen />
                    </>
                );
            }}
        </CacheBuster>
    </BrowserRouter>
);

export default Routes;
