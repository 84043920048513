import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import BarcodeScanner from '../../components/BarcodeScanner/BarcodeScanner';
import Input from '../../components/InputTwo/Input/Input';
import './ChangeCard.css';
import Loading from "../../components/Loading/Loading";
import { ToastsStore } from 'react-toasts';
import { getCustomerInfoBySerialNoAPI, changCardNoAPI, checkStatusCardAPI } from "../../api/api";

function ChangeCard(props) {

    const [loading, setLoading] = useState({ show: false, message: "" });

    //get the barcode number written by the user in components/BarcodeScanner
    const [userBarcode, setUserBarcode] = useState();

    //get information from API
    const [card, setCard] = useState({
        cardSerialNumber: '',
        remainCredit: '',
        cellPhone: '',
        fristName: '',
        lastName: '',
        point: ''
    });

    const [form, setForm] = useState({
        newSerialNo: '',
        oldSerialNo: ''
    });

    useEffect(() => {
        if (userBarcode) {
            setLoading({ ...loading, show: true });
            setCard({});
            setForm({
                newSerialNo: '',
                oldSerialNo: userBarcode
            });

            //get info customer from API
            getCustomerInfoBySerialNoAPI(userBarcode, JSON.parse(localStorage.getItem('userAccountInfo')).Id, response => {
                setLoading({ ...loading, show: false });
                if (response.status === 200) {
                    if (response.data.data) {
                        setCard(response.data.data)
                    } else {
                        ToastsStore.error(response.data.errorMessage)
                    }
                } else if (response.status === 400) {
                    ToastsStore.error(response.data.errorMessage);
                }
            });
        }
    }, [userBarcode])

    const sendForm = (event) => {
        event.preventDefault()
        setLoading({ ...loading, show: true });
        //check newSerialNo
        checkStatusCardAPI(form.newSerialNo, JSON.parse(localStorage.getItem('userAccountInfo')).Id, response => {
            setLoading({ ...loading, show: false });

            if (response.status === 200) {

                if (!response.data.errorMessage && response.data.status === 200) {
                    //change number card 
                    changCardNoAPI(form.newSerialNo, form.oldSerialNo, response => {
                        setUserBarcode()
                        if (response.status === 200) {
                            response.data.errorMessage ? ToastsStore.error(response.data.errorMessage) : ToastsStore.success("تغییر کارت با موفقیت انجام شد");
                        } else if (response.status === 400) {
                            ToastsStore.error(response.data.errorMessage);
                        }
                    });
                } else {
                    ToastsStore.error(response.data.errorMessage);
                }

            } else if (response.status === 400) {
                ToastsStore.error(response.data.errorMessage);
            }

        });
    };

    //setting input
    const settingTextRequired = {
        class: "input-field__change-card--text",
        type: 'text',
        required: true,
        invalidClass: "input-field__change-card--text--alert-required"
    };


    if (localStorage.getItem('token') != null)
        return (
            <>
                <BarcodeScanner title="تغییر کارت" color="var(--change-card)" actionBack={props.history} receiveBarcode={setUserBarcode} />
                {userBarcode
                    ?
                    <div className="Change-card-container unselectable">
                        <Loading loading={loading.show} message={loading.message} />
                        <div className="Change-card-container__card">
                            <p className="Change-card-container__card__text" >اعتبار قابل برداشت : {card.remainCredit ? card.remainCredit : ''}</p>
                            <p className="Change-card-container__card__text" >شماره سریال : {card.cardSerialNumber ? card.cardSerialNumber : ''}</p>
                            <p className="Change-card-container__card__text" >شماره همراه مشتری : {card.cellPhone ? card.cellPhone : ''}</p>
                            <p className="Change-card-container__card__text" >نام و نام خانوادگی : {card.fristName ? `${card.fristName} ${card.lastName}` : ''}</p>
                            <p className="Change-card-container__card__text" >امتیاز فعلی : {card.point ? card.point : ''}</p>
                        </div>
                        <form className="Change-card-container__form" onSubmit={(event) => sendForm(event)}>
                            <div className="Change-card-container__form__group-input">
                                <Input setting={settingTextRequired} label="شماره کارت جدید" setValue={(event) => setForm({ ...form, newSerialNo: event })} />
                            </div>
                            <div className="Change-card-container__form__button">
                                <div className="Change-card-container__form__button--success">
                                    <input type="submit" value="تغییر کارت" />
                                </div>
                            </div>
                        </form>
                    </div>
                    :
                    null
                }
            </>
        );
    else
        return <Redirect to='/login' />
}

export default ChangeCard;
