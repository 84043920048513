import React, { useState } from 'react';
import './DatePicker.css';
import {toEnglish} from '../../Utils'

function DatePicker(props) {

    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');

    const rangeDay = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];
    const rangeMonth = ["فروردین", "اردیبهشت", "خرداد", "تیر", "مرداد", "شهریور", "مهر", "آبان", "آذر", "دی", "بهمن", "اسفند"];
    const rangeYear = [];
    const newYear = new Date().toLocaleDateString('fa-IR').split("/")[0];
    for (let i = toEnglish(newYear); i >= 1199; i--) {
        rangeYear.push(i)
    };

    //action empty input
    const invalid = (event) => {
        event.target.className = props.setting.invalidClass;
    };

    //validation
    const validation = () => {
        props.setValue(year.concat(month, day))
    }

    //onChange
    const onChangeYear = (event) => {
        setYear(event.target.value + "/")
        event.target.className = ""
    }
    const onChangeMonth = (event) => {
        switch (event.target.value) {
            case "فروردین":
                setMonth("01/")
                break;
            case "اردیبهشت":
                setMonth("02/")
                break;
            case "خرداد":
                setMonth("03/")
                break;
            case "تیر":
                setMonth("04/")
                break;
            case "مرداد":
                setMonth("05/")
                break;
            case "شهریور":
                setMonth("06/")
                break;
            case "مهر":
                setMonth("07/")
                break;
            case "آبان":
                setMonth("08/")
                break;
            case "آذر":
                setMonth("09/")
                break;
            case "دی":
                setMonth("10/")
                break;
            case "بهمن":
                setMonth("11/")
                break;
            case "اسفند":
                setMonth("12/")
                break;
            default:
                setMonth()
        }
        event.target.className = ""
    }
    const onChangeDay = (event) => {
        setDay(event.target.value)
        event.target.className = ""
    }

    //generate input
    const generateInput = () => {
        if (props.setting.required) {
            //required field
            return (
                <div className={props.setting.groupClass}>
                    <select onChange={onChangeYear} onInvalid={invalid} required>
                        <option value="" selected disabled>سال</option>
                        {rangeYear.map((item, index) =>
                            <option value={item} key={index}>{item}</option>
                        )}
                    </select>
                    <select onChange={onChangeMonth} onInvalid={invalid} required>
                        <option value="" selected disabled>ماه</option>
                        {rangeMonth.map((item, index) =>
                            <option value={item} key={index}>{item}</option>
                        )}
                    </select>
                    <select onChange={onChangeDay} onInvalid={invalid} required>
                        <option value="" selected disabled>روز</option>
                        {rangeDay.map((item, index) =>
                            <option value={item} key={index}>{item}</option>
                        )}
                    </select>
                </div>
            )
        } else {
            //normal field
            return (
                <div className={props.setting.groupClass}>
                    <select onChange={onChangeYear}>
                        <option value="" selected disabled>سال</option>
                        {rangeYear.map((item, index) =>
                            <option value={item} key={index}>{item}</option>
                        )}
                    </select>
                    <select onChange={onChangeMonth}>
                        <option value="" selected disabled>ماه</option>
                        {rangeMonth.map((item, index) =>
                            <option value={item} key={index}>{item}</option>
                        )}
                    </select>
                    <select onChange={onChangeDay}>
                        <option value="" selected disabled>روز</option>
                        {rangeDay.map((item, index) =>
                            <option value={item} key={index}>{item}</option>
                        )}
                    </select>
                </div>
            )
        }
    }

    return (
        <div className={props.setting.class} onClick={validation}>
            <label>{props.label}</label>
            {generateInput()}
        </div>
    )
}

export default DatePicker;